<template>
	<div class="tcontainer">
		<Toast />
		<div class="clearfix ttitle">
			<div class="pull-right">
				<button class="btn btn-danger btn-sm" @click="removeOld"><i class="fa fa-trash"></i> Remove Old</button>
			</div>
			<div class="title">Notice</div>
		</div>
		<div class="container" style="padding-top: 10px;">
			<div class="row">
				<div class="col-4"></div>
				<div class="col-4">
					<textarea v-model="notice" class="form-control" rows="4" placeholder="Notice content"></textarea>
					<div class="form-group p-fluid">
						Start Date
						<Calendar placeholder="Select Start Date" v-model="start_date" dateFormat="dd/mm/yy" />
					</div>
					<div class="form-group p-fluid">
						End Date
						<Calendar placeholder="Select End Date" v-model="end_date" dateFormat="dd/mm/yy" />
					</div>
					<div class="form-group">
						<label>
							<input type="checkbox" v-model="isImportant"> Important
						</label>
					</div>
					<div class="clearfix" style="margin-top: 10px;">
						<button @click="sendNotice" class="btn btn-lg btn-primary pull-right"><i
								class="fa fa-paper-plane"></i> Send</button>
					</div>
				</div>
				<div class="col-4"></div>
			</div>
		</div>

		<div style="padding-top: 40px;">
			<div style="max-width: 600px; margin: auto;">
				<div style="font-size: 20px;" class="alert" :class="{'alert-danger': n.is_important == 'y', 'alert-info': n.is_important == 'n' }" v-for="n in notifications" :key="n">
					{{ n.message }}

					<div style="font-size: 16px; text-align: right; padding: 8px;" v-if="n.start_date || n.end_date">
						<div v-if="n.start_date">Start Date: {{ format(n.start_date, "DD/MM/YYYY") }}</div>
						<div v-if="n.end_date">End Date: {{ format(n.end_date, "DD/MM/YYYY") }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Toast from 'primevue/toast';
import Calendar from 'primevue/calendar';
export default {
	components: {
		Toast,
		Calendar,
	},
	data() {
		return {
			notifications: [],

			sending: false,
			notice: '',
			start_date: null,
			end_date: null,
			isImportant: false,
		}
	},
	async mounted() {
		this.notifications = await this.get('admin/load-unseen-notifications');
		
	},
	methods: {
		async sendNotice() {
			if (this.sending || !this.notice.length) return;
			this.sending = true;

			let start_date = null;
			if(this.start_date) start_date = this.format(this.start_date, "YYYY-MM-DD");
			let end_date = null;
			if(this.end_date) end_date = this.format(this.end_date, "YYYY-MM-DD");

			await this.post('admin/send-notice', {
				notice: this.notice,
				start_date,
				end_date,
				is_important: this.isImportant ? 'y' : 'n'
			});
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Notice sent successfully', life: 3000 });
			this.sending = false;
			this.notice = '';
			this.isImportant = false;
			this.start_date = null;
			this.end_date = null;

			this.notifications = await this.get('admin/load-unseen-notifications');
		},
		async removeOld(){
			await this.post('admin/remove-old-notice', {})
			this.$toast.add({ severity: 'success', summary: 'Success', detail: 'Old notice removed successfully', life: 3000 });

			this.notifications = await this.get('admin/load-unseen-notifications');

		}
	}
}
</script>
<style scoped>

</style>